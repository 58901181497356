<template>
    <v-container fluid>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/veiculos/marca" />
        </v-col>
      </v-row>
  
      <marca-veiculo-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
    </v-container>
  </template>
  
  <script>  
  import marcas from '@/api/marcas'
    export default {
      name: 'AdicionarMarcaVeiculo',
  
      components: {
      MarcaVeiculoCampos: () => import('./MarcaVeiculoCampos'),
    },

  
      data () {
        return {
          carregando: false,
          validacaoFormulario: {},
        }
      },
  
      methods: {
        async salvar (marca) {
          this.carregando = true
          this.validacaoFormulario = {}
  
          try { 
            await marcas.inserir(marca)

            this.$router.push('/veiculos/marca', () => this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_adicionar') }))
          } catch (e) {
            if (e.response.status === 422) {
              this.validacaoFormulario = this.$erroApi.validacao(e)
              return
            }
  
            this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
          } finally {
            this.carregando = false
          }
        },
      },
    }
  </script>
  